import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { useStaticQuery, graphql } from "gatsby"
import WorkDetail from "../../components/workdetail"
import kbcVideo from "../../assets/kbc.mp4"

import ReactPlayer from "react-player"

import { playerWrapper169, reactPlayer } from "./kbc.module.css"

const query = graphql`
  {
    allFile(filter: { name: { eq: "KBC" } }) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
`

const Kbc = () => {
  const data = useStaticQuery(query)
  const KBCImage = data.allFile.nodes[0].childImageSharp.gatsbyImageData

  return (
    <Layout>
      <Seo title="KBC" />
      <WorkDetail
        company="KBC"
        image={KBCImage}
        description="A series of animated explainer videos that explain the benefits of Microsoft Teams to the employees of KBC."
        brief="One of the consequences of the COVID-19 crisis was the shift to remote working. Microsoft noticed that the employees of one of their key accounts, KBC, had difficulties implementing digital tools into their daily work-related activities."
        solution="We made a series of five simple, animated explainer videos that shows the benefits of Microsoft Teams in terms of productivity and health. During this project I worked closely with an illustrator, 
Gauvain Debisschop."
      />
      <div className={playerWrapper169}>
        <ReactPlayer
          url={kbcVideo}
          controls={true}
          width="100%"
          height="100%"
          pip={true}
          className={reactPlayer}
        />
      </div>
    </Layout>
  )
}

export default Kbc
